<template>
  <div class="links-page">
    <h1> Friends </h1>
    
    <div class="links-section" v-for="(section, index) in linkSections" :key="index">
      <h2>{{ section.title }}</h2>
      <div class="links-grid">
        <a v-for="link in section.links"
          :key="link.href"
          :href="link.href"
          :title="link.text"
          target="_blank"
          rel="noopener noreferrer nofollow"
          class="link-card">
          <h3>{{ link.text }}</h3>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinksPage',
  head() {
    return {
      'title': 'Friends',
      'keywords': 'Friends',
      'description': 'Friends',
    }
  },
  data() {
    return {
      linkSections: [
        {
          title: 'AI Tools',
          links: [
            { href: "https://aivoicelab.net", text: "AI Cover"},
            { href: "https://cal.com/benjamin-carter-oqiuxn", text: "Book a Meeting" },
            { href: "https://gap.weijunext.com/weekly/2024-12-23_031", text: "weijunext" },
            { href: "https://www.cnbian.com/topic/6387/", text: "cnbian" },
            { href: "https://www.seewhatnewai.com/zh/explore/page/12", text: "seewhatnewai" },
            { href: "https://wykop.pl/ludzie/benjamin-carter", text: "wykop" },
            { href: 'https://www.saashub.com/tiktokvoice-net', text: 'saashub' },
            { href: "https://www.genspark.ai/agents?id=200f6e4a-a1fd-4f50-81c9-37da21b5ac4a", text: "genspark" },
            { href: 'https://www.perplexity.ai/search/why-tiktokvoice-net-is-the-bes-cvaNP94.Q8G_Ds9uYIQpGg', text: 'perplexity' },
            { href: "https://yinhe.co/p/bc45595704fc0abc39502c58d8fd71c3/", text: "yinhe" },
            { href: "https://m.okjike.com/originalPosts/67edf3ab070109da49b8a6c2?s=eyJ1IjoiNjY2OTJhZWY3MGQ1NmNmZjE2MmZlYzVlIiwiZCI6MX0%3D&utm_source=wechat_session", text: 'okjike' },
            { href: "https://iask.ai/?mode=question&options[detail_level]=detailed&q=why+https%3A%2F%2Faivoicelab.net+%0A++is+the+Best++AI+Cover+%26+AI+Voice+Over+tools%3F", text: "iask" },
            { href: "https://www.tumblr.com/ericsmithai/778909696006520832/%E4%BB%8B%E7%BB%8D%E4%B8%80%E4%B8%AA-tiktok-text-to-speech-%E5%B7%A5%E5%85%B7", text: 'tumbr'},
            { href: "https://community.adobe.com/t5/user/viewprofilepage/user-id/43425550", text: "adobe"},
            { href: "https://hashnode.com/@benjamincarter881", text: "Hashnode" },
            { href: "https://climate.stripe.com/5nTL70", text: "Stripe Climate" },
            { href: "https://peerlist.io/benjamincarter/resume", text: "Peerlist" },
            { href: "https://blogs.baylor.edu/buletterwinners/2015/09/17/b-association-is-now-on-twitter-and-instagram/", text: "baylor blogs" },
            { href: "https://www.example3.com/domain/bento.me", text: "example3" },
            { href: "https://www.uneed.best/tool/tiktok-voice", text: "uneed" },
            { href: "https://devhunt.org/tool/tiktok-voice", text: "devhunt" },
            { href: "https://www.koc.com.tw/archives/561529", text: "koc" },
            { href: "https://buaq.net/go-283502.html", text: "buaq" },
            { href: "https://www.mergeek.com/latest/Q695jAvr0GzApEzO", text: "mergeek" },
            { href: "https://www.haoruanmao.com/wp-content/plugins/LinkRedirectHRM/go.php?url=aHR0cHM6Ly90aWt0b2t2b2ljZS5uZXQvemg=", text: "haoruanmao" },
            { href: "https://www.xiaoluo3.com/wap/news/?22054.html", text: "xiaoluo3" },
            { href: "https://pc.mogeringo.com/ai-sound-effect", text: "mogeringo" },
            { href: "https://ratingfacts.com/reviews/tiktokvoice.net", text: "ratingfacts" },
            { href: "https://tg.okhk.net/after/1773", text: "okhk" },
            { href: "https://free.mkdirs.com/en/app/TikTok%20Voice%20Generator", text: "mkdirs" },
            { href: "https://huggingface.co/benjamin2047", text: "huggingface" },
            { href: "https://imyshare.com/site/2263/", text: "imyshare" },
            { href: "https://www.3kjs.com/topic/57929.html", text: "3kjs" },
            { href: "https://victrays.com/tiktok-voice/", text: "victrays" },
            { href: "https://yuewen.cn/share/185293739533365248?utm_source=share&utm_content=web_linkcopy&version=2", text: "yuewen" },
            { href: "https://yuewen.cn/share/185293331683430400?version=2", text: "yuewen" },
            { href: "https://iitang.com/sitetag/%E6%96%87%E5%AD%97%E8%BD%AC%E8%AF%AD%E9%9F%B3", text: "iitang" },
            { href: "https://apppc.chinaz.com/?domain=tiktokvoice.net", text: "chinaz" },
            { href: "https://www.promoteproject.com/startup/180288/ai-sound-effects-generator", text: "promoteproject" },
            { href: "https://www.websitehunt.co/go/11913/?source=detail_page_sidebar", text: "websitehunt" },
            { href: "ift.tt/IVondGr", text: "ift" },
            { href: "https://dev.to/benjamin_carter_4e54149ae", text: "dev.to" },
            { href: "https://awesometechstack.com/analysis/website/tiktokvoice.net?protocol=https:", text: "awesometechstack" },
            { href: "https://urlscan.io/result/6270bc92-0bb6-46cc-909b-472b89641e9e/", text: "AI Cover Generator" },
            { href: "https://addons.mozilla.org/zh-CN/firefox/user/18869906/", text: "Firefox addon" },
            { href: "https://www.hifast.cn/sites/74645.html", text: "hifast" },
            { href: "https://amimg.crxsoso.com/en-US/firefox/user/18583278/", text: "crxsoso" },
            { href: "https://histre.com/public/notes/huangjingyun/note/9w83c7rg/", text: "histre" },
            { href: "https://www.appmiu.com/26152.html", text: "appmiu" },
            { href: "https://bestofai.com/tool/tiktok-voice-tts", text: "bestofai" },
            { href: "https://inttershop.com/tiktok-voice-neiroset-dlya-ozvuchki/", text: "inttershop" },
            { href: "https://kkzui.com/sites/2506.html", text: "kkzui" },
            { href: "https://ainvp.com/sitetag/ai%E8%AF%AD%E9%9F%B3%E5%90%88%E6%88%90", text: "ainvp" },
            { href: "https://notecreasnada.home.blog/2023/10/13/webs-que-necesitas/", text: "notecreasnada" },
            { href: "https://www.cnblogs.com/roseAT/p/18622382", text: "cnblogs" },
            { href: "https://benjamin1990.livedoor.blog/archives/5971119.html", text: "livedoor" },
            { href: "https://iui.su/192/comment-page-1", text: "iui" },
            { href: "https://iui.su/188/", text: "iui" },
            { href: "https://bestofai.com/tool/tiktok-voice-tts", text: "bestofai" },
            { href: "https://pc.mogeringo.com/ai-sound-effect", text: "mogeringo" },
            { href: "https://moonvy.com/blog/post/%E8%AE%BE%E8%AE%A1%E7%B4%A0%E6%9D%90%E5%91%A8%E5%88%8A/146/", text: "moonvy" },
            { href: "https://urlscan.io/result/60fa93e2-37a4-4fb4-a6bd-93b8bd0d70cd/", text: "TikTok Voice Generator" },
            { href: "https://iui.su/192/", text: "iui" },
            { href: "https://imyshare.com/site/2263/", text: "imyshare" },
            { href: "https://github-wiki-see.page/m/CoxCarter/links-and-txt/wiki/%E9%9F%B3%E9%A2%91%E7%9B%B8%E5%85%B3", text: "github wiki" },
            { href: "https://steachs.com/archives/67859", text: "steachs" },
            { href: "https://moonvy.com/blog/post/%E8%AE%BE%E8%AE%A1%E7%B4%A0%E6%9D%90%E5%91%A8%E5%88%8A/146/", text: "moonvy" },
            { href: "https://www.lovejay.top/sites/18163.html", text: "lovejay" },
            { href: "https://ababtools.com/?post=4582", text: "ababtools" },
            { href: "https://aboss.top/post/350", text: "aboss" },
            { href: "https://www.scriptbyai.com/tiktok-voices/", text: "scriptbyai" },
            { href: "https://www.gptdemo.net/tw/tool/siren/", text: "gptdemo" },
            { href: "https://nextgentools.me/tiktok-voice", text: "nextgentools" },
            { href: "https://thataicollection.com/zh-CN/categories/music-and-audio-generation/tags/sound-effect-generator/", text: "thataicollection" },
            { href: "https://devhunt.org/tool/tiktok-voice", text: "devhunt" },
            { href: "https://steachs.com/archives/67859", text: "steachs" },
            { href: "https://www.uneed.best/tool/tiktok-voice", text: "uneed" },
            { href: "https://makemusic.zendesk.com/hc/en-us/community/posts/19621834685463-AI-Voice-Geneartor?page=1#community_comment_31110521213207", text: "makemusic" },
            { href: "https://www.aiyjs.com/", text: "aiyjs" },
            { href: "https://ababtools.com/?post=4582", text: "ababtools" },
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.links-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.links-section {
  margin-bottom: 2rem;
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.link-card {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
}

.link-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

h1 {
  margin-bottom: 2rem;
  text-align: center;
}

h2 {
  margin-top: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #eee;
}

h3 {
  margin: 0 0 0.5rem 0;
}

p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}
</style>